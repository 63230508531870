body {
  background: var(--white);
}

.landing__nav {
  height: 3rem;
  margin-inline: 2rem;
  margin-block: 1rem;
  z-index: 100;
}

.landing__hero {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: -5%;
  object-fit: fill;
  z-index: -1;
}

.landing__quadrants {
  padding: 1rem;
  border: dashed 1px var(--dark-16);
  --border-radius: 16px;
}
.landing__quadrants * {
  --border-radius: unset;
}
.landing__quadrants > *:nth-child(3n + 1) {
  background: linear-gradient(139.08deg, rgba(145, 228, 255, 0.0792) 0%, rgba(63, 151, 255, 0.08) 100%);
}

.landing__faq {
  --flow-gap: 0.75rem;
}

.landing__footer {
  padding-inline: 4rem;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: auto auto;
  column-gap: 4rem;
  row-gap: 2rem;
}

.special__padding {
  padding-left: var(--padding-inline, 4rem);
  padding-right: var(--padding-inline, 4rem);
}

.details.border:not([open]) {
  border: solid 1px var(--dark-8);
}
.details.border[open] {
  box-shadow:
    0px 0px 1px rgba(26, 32, 36, 0.32),
    0px 1px 2px rgba(91, 104, 113, 0.32);
}

.logo-box {
  display: flex;
  gap: 10px;
  margin-top: 17px;
}
.f-margin {
  margin-top: 5px;
}

.hamburger {
  display: none;
}

.close-navbar {
  display: none;
}

.launch-nav {
  display: flex;
  padding: 16px 26px;
  align-items: center;
  gap: 40px;
  border-radius: 26px;
}

@media all and (max-width: 900px) {
  .hamburger {
    display: block;
  }

  .launch-nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    transition: 1s;
    transform: translateY(-100vh);
    justify-content: center;
    background: #f7f7f7;
    border-radius: 26px;
  }

  header .responsive_nav {
    transform: none;
    margin-left: 0;
    font-size: 2rem;
    z-index: 100;
  }

  nav .close-navbar {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  nav .login-nav {
    display: block;
  }
}

/* .launch-nav {
  margin-left: 3%;
} */

.landing-link {
  /* margin: 1.25rem; */
  /* color: var(--Dark-72, rgba(8, 12, 16, 0.72)); */
  text-align: center;
  /* font-family: "sfpro-semibold"; */
  font-size: 18px;
  line-height: normal;
}

.login-nav {
  display: none;
}

.fixed-header-container {
  z-index: 999;
  width: 100%;
  top: 0;
  position: fixed;
  height: 110px;
}
.launch-header {
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  z-index: 999;
  width: 100%;
  /* top: 0;
	/position: fixed; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 24px 60px;
  color: rgba(102, 115, 133, 1);
}

@media (max-width: 640px) {
  .launch-header {
    padding: 24px 32px;
    background-color: white;
  }
}

.fixed-header-spacer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 110px;
  padding: 0 20px;
  color: rgba(102, 115, 133, 1);
}

.email-error {
  border: 1px solid red;
}

.email-error2 {
  border: 0px solid red;
}
